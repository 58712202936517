/* ============ */
/* Local imports */
/* ============ */

/* CSS */

import '../style/Global.css'
import '../style/Register.css'

/* Shared */

import Footer from './shared/Footer';

/* ================ */
/* External imports */
/* ================ */

/* React imports*/

import React, { useState, useEffect, useRef } from 'react';

/* Prime imports */

import "primeflex/primeflex.css";
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';

import { useForm, Controller } from 'react-hook-form';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';

// import { StyleClass } from 'primereact/styleclass';

/* Firebase imports */
import { auth } from "../../firebase.config.js";
import { signOut, createUserWithEmailAndPassword } from "firebase/auth";


export default function Register() {

  useEffect(() => console.log('Register mounted'), []);

  /* Register form */
  const form = useRef();
  const toast = useRef(null);

  const [formData, setFormData] = useState({});
  const defaultValues = {
    name: '',
    email: '',
    password: '',
    accept: false
  }

  const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

  const onSubmit = (data) => {
    console.log("onSubmit")
    setFormData(data);
    register();
    redirectToHomepage();
    reset();
  };

  const getFormErrorMessage = (name) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };

  const passwordHeader = <h6>Mot de passe</h6>;
  const passwordFooter = (
    <React.Fragment>
      <Divider />
      <p className="mt-2">Obligations</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
        <li>Minimum 6 charactères</li>
      </ul>
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
        <li>Minimum une miniscule</li>
        <li>Minimum une majuscule</li>
        <li>Minimum un chiffre</li>
      </ul>
    </React.Fragment>
  );

  /* Firebase */
  let [registeredPwd, setRegisteredPwd] = useState("");
  let [registeredEmail, setRegisteredEmail] = useState("");
  const register = async () => {
    console.log("Register")
    registeredEmail = event.target[1].value;
    registeredPwd = event.target[2].value;
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registeredEmail,
        registeredPwd
      );
      console.log(user)
    } catch (error) {
      console.log(error.message);
    }
  };

  /* Redirection */
  function redirectToHomepage() {
    window.open("http://localhost:8080/home"); // Open new window with account dashboard
  };

  return (

    <div class="registerBackground">

      {/* Register */}

      <Toast ref={toast} />

      <div class="registerContainer">
        <div className="flex align-items-center justify-content-center">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <div className="text-center mb-5">
              {/* <img src={GAPLogo} alt="gap" height={50} className="mb-3" /> */}
              <div className="text-900 text-3xl font-medium mb-3">Créer un compte</div>
            </div>

            <div>
              <div className="flex justify-content-center">
                <form ref={form} onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                  <div className="field">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-user" />
                      <Controller name="name" control={control} rules={{ required: 'Nom requis' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                      )} />
                      <label htmlFor="name" className={classNames({ 'p-error': errors.name })} id="formInputColor">Nom*</label>
                    </span>
                    {getFormErrorMessage('name')}
                  </div>
                  <div className="field" id="registerSpace">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-envelope" />
                      <Controller name="email" control={control}
                        rules={{ required: 'Adresse email requise.', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Adresse email invalide. E.g. exemple@email.com' } }}
                        render={({ field, fieldState }) => (
                          <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                      <label htmlFor="email" className={classNames({ 'p-error': errors.email })} id="formInputColor">Email*</label>
                    </span>
                    {getFormErrorMessage('email')}
                  </div>
                  <div className="field" id="registerSpace">
                    <span className="p-float-label p-input-icon-right" >
                      <Controller name="password" control={control} rules={{ required: 'Mot de passe requis' }} render={({ field, fieldState }) => (
                        <Password id={field.name} {...field} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} header={passwordHeader} footer={passwordFooter} />
                      )} />
                      <label htmlFor="password" className={classNames({ 'p-error': errors.password })} id="formInputColor">Mot de passe*</label>
                    </span>
                    {getFormErrorMessage('password')}
                  </div>
                  <div className="field-checkbox">
                    <Controller name="accept" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                      <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    <label htmlFor="accept" className={classNames({ 'p-error': errors.accept })}>J'accepte les termes et conditions*</label>
                  </div>

                  <Button type="submit" label="Envoyer" className="p-button-raised mt-2" id="registerSendBtn" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
}