/* ============ */
/* Local imports */
/* ============ */

/* Media */
import dashboardBackground from "../media/dashboard/dashboardBackground.jpg";

/* CSS */
import '../style/Global.css'
import '../style/Dashboard.css'

/* Shared */
import Navigation from './shared/Navigation'

/* ================ */
/* External imports */
/* ================ */

/* React imports*/
import React, { useState, useEffect } from 'react';

/* Prime imports */
import "primeflex/primeflex.css";
// import { StyleClass } from 'primereact/styleclass';

/* FireBase & Firestore */
import { auth } from "../../firebase.config.js";
import { app } from "../../firebase.config.js";
import { onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { doc, getDoc, getDocs, collection } from "firebase/firestore";


export default function Dashboard() {

  const db = getFirestore(app);

  // let [getEmail, setEmail] = useState({});
  // onAuthStateChanged(auth, (currentUser) => {
  //   try {
  //     setEmail(currentUser.email);
  //     console.log("Current user logged-in: " + getEmail);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // })
  // async function asyncGet() {
  //   try {
  //     const querySnapshot = await getDocs(collection(db, getEmail));
  //     querySnapshot.forEach((doc) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       if (doc.exists()) {
  //         console.log(doc.id, ":", doc.data());
  //       } else {
  //         // doc.data() will be undefined in this case
  //         console.log("No such data!");
  //       }
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
  // asyncGet();

  useEffect(() => {
    console.log('Account dashboard mounted');
  }, []);


  return (

    <div>
      <Navigation />

      <div className='dashboardMainContainer'>
        <img alt="dashboardBackground" src={dashboardBackground} id="dashboardBackground" />
        <br /><br />
        <div className='dashboardContainer'>
          <div className='dashboardLeftContainer'>
            <div className='top'>
              Dashboard presentation
            </div>
            <div className='middle'>
              Current project: prestation, entreprise, date, ...
            </div>
            <div className='bottom'>
              Project phases: timeline
            </div>
          </div>
          <div className='dashboardRightContainer'>
            <div className='dashboardUserInfo'>
              User info
            </div>
            <div className='dashboardAllProjects'>
              All projects
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}