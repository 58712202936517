export const portfolioDataCJ = [
    {
        path: require("../../../media/portfolio/C&J/c&jlogo.jpg"),
        name: "CJ",
        description: "Logo"
    },
    {
        path: require("../../../media/portfolio/C&J/Image2.png"),
        name: "CJ",
        description: "Services"
    },
    {
        path: require("../../../media/portfolio/C&J/Image3.png"),
        name: "CJ",
        description: "Minimalisme"
    },
    {
        path: require("../../../media/portfolio/C&J/Image4.png"),
        name: "CJ",
        description: "Minimalisme"
    },
    {
        path: require("../../../media/portfolio/C&J/Image5.png"),
        name: "CJ",
        description: "Minimalisme"
    },
];