/* ============ */
/* Local imports */
/* ============ */

/* CSS */

import '../style/Global.css'

/* ================ */
/* External imports */
/* ================ */

/* React & router imports*/

import React, { useEffect } from 'react';
import {
  Link
} from "react-router-dom";

/* Prime imports */

import "primeflex/primeflex.css";
// import { StyleClass } from 'primereact/styleclass';


export default function NotFoundPage() {

  useEffect(() => console.log('NotFoundPage mounted'), []);

  return (

    <div>

      <div className='mainContainer'>
        <p class="text-3xl w-10">NotFoundPage</p>
        <p class="text-3x2 w-10">
          Veuillez vous rediriger vers la <Link to="/home">page d'accueil</Link>
        </p>
      </div>

    </div>

  );
}