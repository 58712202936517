/* ================ */
/* External imports */
/* ================ */

/* React & Router imports */

import React, { useState, useEffect } from 'react';
import { Navigate, Routes, Route, useParams} from 'react-router-dom';

/* Prime imports */

import "primereact/resources/themes/saga-orange/theme.css" // theme (free themes: https://primefaces.org/primereact/setup/)
import "primereact/resources/primereact.min.css"; // core css
import "primeicons/primeicons.css"; // icons

/* Sentry */

import * as Sentry from "@sentry/react";

/* Firebase */
import {auth} from "./firebase.config.js";
import {onAuthStateChanged} from "firebase/auth";

/* ============ */
/* Local imports */
/* ============ */

import Loader from './controller/functions/Loader';

// import Navigation from './views/components/shared/Navigation';
import FeedbackNav from './views/components/shared/FeedbackNav';
import ScrollIndicator from './views/components/shared/ScrollIndicator';
import ScrollToTop from './views/components/shared/ScrollToTop';

import Home from './views/components/Home';

import Register from './views/components/Register';
import Password from './views/components/Password';
import Dashboard from './views/components/Dashboard';

import NotFoundPage from './views/components/NotFoundPage';

function App() {

  // Firebase uid
  let {getUid} = useParams();
  onAuthStateChanged(auth, (currentUser) => {
    getUid = currentUser.uid;
    // console.log("uid: " + getUid);
  })


  // Loader
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    },1000)
   console.log('React App mounted');
  }, [])

  return loader ? 
  (
    <Loader/>
  )  :  (
    <>
      <div>
        {/* <Navigation /> */}
        <FeedbackNav />
        <ScrollIndicator />
        <ScrollToTop/>
      </div>

        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/home' element={<Home/>} />

          <Route path='/account/register' element={<Register/>} />
          <Route path='/account/password' element={<Password/>} />
          <Route path="/account/dashboard:getUid" element={<Dashboard/>} />

          <Route path='/NotFoundPage' element={<NotFoundPage/>} />
          <Route path="*" element={<Navigate to="/NotFoundPage" />}/>
        </Routes>
    </>
  );

}

export default Sentry.withProfiler(App);