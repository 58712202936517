/* ============ */
/* Local imports */
/* ============ */

/* CSS */
import '../style/Global.css'
import '../style/Home.scss'
import '../style/Prestations.css'

/* Shared */
import Navigation from './shared/Navigation'
import Footer from './shared/Footer';
/* Components */
import Portfolio from './portfolio/Portfolio';

/* Media */
import contact from '../media/navbar/contact.png';
import videoBanner01 from '../media/logoBanner01.mp4';
import videoBanner02 from '../media/logoBanner02.mp4';
import logo from '../media/logo.png';
import prestations from '../media/navbar/prestations.png';
import brochure from '../media/prestations/brochure.jpg';
import businessCard from '../media/prestations/businessCard.jpg';
import flyer from '../media/prestations/flyer.jpg';
import poster from '../media/prestations/poster.jpg';
import invitationCard from '../media/prestations/invitationCard.jpg';
import productPackage from '../media/prestations/productPackage.jpg';
import visualIdentity from '../media/prestations/visualIdentity.jpg';
import logoPrestation from '../media/prestations/logo.jpg';
import emailing from '../media/prestations/emailing.jpg';
import adBanner from '../media/prestations/adBanner.jpg';
import personalizedVisual from '../media/prestations/personalizedVisual.jpg';
import launchingPack from '../media/prestations/launchingPack.jpg';
import landingPage from '../media/prestations/landingPage.jpg';
import siteVitrine from '../media/prestations/siteVitrine.jpg';
import siteSurMesure from '../media/prestations/siteSurMesure.jpg';

/* ================ */
/* External imports */
/* ================ */

/* React imports*/
import React, { useState, useRef, useEffect } from 'react';

/* Prime imports */
import "primeflex/primeflex.css";
// import { StyleClass } from 'primereact/styleclass';
// import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Avatar } from 'primereact/avatar';

/* EmailJS */
import emailjs from '@emailjs/browser';

/* Animations */
// https://github.com/MetinArslanturk/react-animation-on-scroll
// https://www.metinarslanturk.com/react-animation-on-scroll/
// https://animate.style/
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function Home() {

  /* 
  ======
  Banner
  ======
  */
  const vidArray = [
    videoBanner01,
    videoBanner02,
  ];
  const randomVideo =
    vidArray[Math.floor(Math.random() * vidArray.length)];

  const vidRef = useRef();
  const scrollDownAfterVideo = useRef();
  const scrollToPresentation = () => {
    scrollDownAfterVideo.current?.scrollIntoView({ behavior: 'smooth' });
  }
  useEffect(() => {
    console.log('Home mounted')
    vidRef.current.play();
  }, []);

  /* 
  ===========
  Prestations
  ===========
  */

  function PrestationCard(props) {
    return (
      <li>
        <div class="card">
          <img src={props.picture} class="card__image" alt="" />
          <div class="card__overlay">
            <div class="card__header">
              <svg class="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg>
              {/* <span class="card__thumb" src="https://i.imgur.com/7D7I6dI.png" alt="" /> */}
              <div class="card__header-text">
                <h3 class="card__title">{props.title}</h3>
                <span class="card__status">Prix minimum: {props.price}</span>
              </div>
            </div>
            <p class="card__description">{props.description}</p>
          </div>
        </div>
      </li>
    );
  }

  /* 
  =======
  Contact
  =======
  */
  const toast = useRef(null);
  const showSuccess = () => {
    toast.current.show({ severity: 'success', summary: 'Email envoyé avec succès !', detail: 'Nous vous contacterons rapidement', life: 3000 });
  }
  const [registeredName, setRegisteredName] = useState('');
  const [registeredFirstName, setRegisteredFirstName] = useState('');
  const [registeredEmail, setRegisteredEmail] = useState('');
  const [registeredEmailText, setEmailText] = useState('Veuillez taper votre email ici');
  const [formData, setFormData] = useState({});
  const defaultValues = {
    name: 'Name is empty',
    firstName: 'First name is empty',
    Email: 'Email is empty',
    emailText: 'Email text is empty'
  }
  const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
  const onSubmit = (data) => {
    setFormData(data);
    submitChecker();
    reset();
  };
  /* Checkers: Policy requirements */
  function submitChecker() {
    console.log("Handle submit")
    let verifications = "";
    if (registeredEmail && registeredEmail != "") {
      console.log("Email policy requirements is correct");
    } else {
      console.log("Email policy requirements is not correct")
      verifications += "Email";
    }
    if ((registeredName && registeredName != "")) {
      console.log("Name policy requirements is correct");
    } else {
      console.log("Name policy requirements is not correct")
      verifications += "Nom";
    }
    if ((registeredFirstName && registeredFirstName != "")) {
      console.log("First name policy requirements is correct");
    } else {
      console.log("First name policy requirements is not correct")
      verifications += "Prénom";
    }
    if ((registeredEmailText && registeredEmailText != "")) {
      console.log("Email text policy requirements is correct");
    } else {
      console.log("Email text policy requirements is not correct")
      verifications += "Contenu de l'email";
    }
    if (verifications && verifications != "") {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Veuillez vérifier le(s) champ(s) suivant(s): ' + verifications, life: 3000 });
    } else {
      sendEmail();
    }
  }
  const form = useRef();
  const sendEmail = () => {
    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    // "service_e88g0jg","template_p0evbos","jzKvLruPClzmTUsWW"
    var templateParams = {
      name: registeredName,
      firstName: registeredFirstName,
      email: registeredEmail,
      emailText: registeredEmailText
    };
    emailjs.send('service_e88g0jg', 'template_p0evbos', templateParams, 'jzKvLruPClzmTUsWW')
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
        showSuccess();
      }, function (error) {
        console.log('FAILED...', error);
        toast.current.show({ severity: 'error', summary: 'Error', detail: error, life: 3000 });
      });
  };

  /* 
  ==========
  Animations
  ==========
  */

  return (

    <div>

      <Toast ref={toast} />

      <div className='mainContainer'>

        {/* Video */}
        <div className='videoContainer'>
          <video
            src={randomVideo} // random video
            ref={vidRef} // random video
            autoPlay
            muted
            preLoad="auto"
            onEnded={scrollToPresentation}
            id="resizeVideoBanner"
          // loop 
          />
        </div>

        <Navigation />

        {/* Banner & Presentation */}
        <br /><br /><br /><br /><br /><br />
        <div className='presentationContainer'>
          <div class="bannerBackground" id="presentation" ref={scrollDownAfterVideo}>
            <div class="cube"></div>
            <div class="cube"></div>
            <div class="cube"></div>
            <div class="cube"></div>
            <div class="cube"></div>
            <div class="bannerContainer">
              <div class="bannerItem">
                <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce={true} initiallyVisible={false} duration={1.5}>
                  <div class="bannerItemTitleContainer">
                    <p class="bannerItemTitleContent">
                      Partner InDesign
                    </p>
                  </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={2.25} delay={500}>
                  <div class="bannerItemDescription">
                    <p>
                      Quand le graphisme devient une passion familiale…
                    </p>
                    <p>
                      Nous sommes deux professionnels du design, l’un spécialisé dans le graphisme et l’autre dans la conception de site web. Nos différents
                      parcours nous permettent d’avoir acquis des compétences complémentaires qui s’unissent dans un but commun : vous aider à réaliser vos
                      projets.
                    </p>
                    <p>
                      Depuis quelques années nous exerçons nos métiers en tant que freelance. Tous deux passionnés et motivés nous avons décider de
                      s’associer pour pouvoir vous accompagner encore plus loin, plus rapidement avec la même qualité.
                    </p>
                    <br />
                    <p><span id="orangeText">Steven:</span> Concepteur Web &nbsp;&nbsp; - &nbsp;&nbsp; <span id="orangeText">Alexia:</span> Graphiste</p>
                  </div>
                </AnimationOnScroll>
              </div>
              <div class="bannerItem">
                <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={2.25} delay={1000}>
                  <img src={logo} class="bannerPicture" id="bannerPictureMask" alt="Partner InDesign logo" />
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>


        {/* Portfolio */}
        <span id="portfolio" />
        <br /><br /><br /><br />
        <Portfolio />
        <br /><br /><br /><br /><br />

        {/* Prestations */}
        <span id="prestations" />
        <br /><br />
        <div>
          <div class="skew-c" />
          <div class="colour-block">
            <br /><br />
            <br /><br /><br /><br />
            <div className='prestationsContainer'>
              <div class='subTitleWrapper'>
                <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} initiallyVisible={false} duration={1}>
                  <h1 id="h1prestations">Prestations</h1>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} initiallyVisible={false} duration={1} delay={100}>
                  <span id='subTitlePrestations'>Nos services adaptés à tous</span>
                </AnimationOnScroll>
                <br />
                <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={1} delay={350}>
                  <img src={prestations} id="responsiveTitleLogos" style={{ width: '5%', opacity: '1' }} />
                </AnimationOnScroll>
              </div>
              <br/><br/>
              <div class="launchPack">
                <div class="customCard">
                  <PrestationCard picture={launchingPack} title="Forfait de lancement" price="250€ (x12 mois)" description="Création de l'identité visuelle, cartes de visite, site vitrine et d'un extra au choix (*) + un mois de community management OFFERT" />
                  <span class="customCardDescription">(*): 5 visuels personnalisés, emailing, 2 flyers personnalisés</span>
                </div>
              </div>
              <br/>
              <span class="prestationSubTitle">Print</span>
              <br/><br/>
              <ul class="cards">
                <PrestationCard picture={brochure} title="Brochure" price="300€" description="Création et mise en page d'une brochure (de 4 à 12 pages) au format A4 ou A5 livré avec le fichier SVG" />
                <PrestationCard picture={businessCard} title="Carte de visite" price="150€" description="Création de carte de visite recto/verso au format standard. Livré avec le fichier SVG et 50 cartes de visite imprimées" />
                <PrestationCard picture={flyer} title="Flyer" price="250€" description="Création d'un flyer recto/verso au format A6 ou A5 livré avec le fichier SVG" />
                <PrestationCard picture={poster} title="Affiche" price="500€" description="Création d'une affiche (format au choix: A1/A2/A3/A4) livré avec le fichier SVG" />
                {/* <PrestationCard picture={productPackage} title="Emballage de produit" price="---" description="" /> */}
                <PrestationCard picture={invitationCard} title="Carton d'invitation" price="80€" description="Création de carton au format au choix (A4/A5/A6)" />
                <PrestationCard picture={visualIdentity} title="Identité visuelle" price="700€" description="Le logo, les couleurs, la typographie, les déclinaisons et les interdits livrés avec un fichier PDF" />
              </ul>
              <div style={{width: "100%"}}>
                <span class="prestationSubTitleRight">Web</span>
              </div>
              <br/><br/>
              <ul class="cards">
                <PrestationCard picture={logoPrestation} title="Logo" price="300€" description="Création de deux pistes de logo avec ses déclinaisons (format SVG)" />
                <PrestationCard picture={emailing} title="Emailing" price="200€" description="Création d'une page standard 15x8cm livré avec le fichier SVG" />
                <PrestationCard picture={adBanner} title="Bannière publicitaire" price="400€" description="Création d'une bannière publicitaire au format souhaité" />
                <PrestationCard picture={personalizedVisual} title="Visuel personnalisé" price="100€" description="Conception d'un visuel personnalisé print ou web au format défini livré avec le ficiher SVG" />
                <PrestationCard picture={landingPage} title="Landing page" price="500€" description="Création d'une landing page au format standard" />
                <PrestationCard picture={siteVitrine} title="Site vitrine" price="1500€" description="Conception d'un site vitrine personnalisé intégrant les outils de référencement de base" />
                <PrestationCard picture={siteSurMesure} title="Site web sur mesure" price="5000€" description="Conception d'un site web sur mesure intégrant les fonctionnalités et services souhaités" />
              </ul>
              <div style={{width: "100%"}}>
                <span class="prestationSubTitleCenter">Community management</span>
              </div>
              <br/><br/>
              <div style={{width: "70%", textAlign: "center", marginRight: "auto", marginLeft: "auto"}}>
                <div className="grid">
                  <div className="col-12 lg:col-4">
                    <div className="p-3 h-full">
                      <div className="shadow-3 p-3 h-full flex flex-column" style={{ borderRadius: '6px', backgroundColor: "#f7f7f7" }}>
                        <div className="text-900 font-medium text-xl mb-2">Présence</div>
                        <div className="text-600">Idéal pour les entreprises ayant déjà une clientèle habituelle</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="align-items-center">
                          <span className="font-bold text-2xl text-900">1 an: 300€/mois</span>
                          <br/>
                          <span className="font-medium text-600">6 mois: 325€/mois</span>
                          <br/>
                          <span className="font-medium text-600">3 mois: 350€/mois</span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <ul className="list-none p-0 m-0 flex-grow-1">
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span>1 réseau social</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span>8 publications</span>
                          </li>
                        </ul>
                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                        <a href="#contact" class="spanLink"><Button label="Contactez-nous" className="p-3 w-full" /></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 lg:col-4">
                    <div className="p-3 h-full">
                      <div className="shadow-3 p-3 h-full flex flex-column" style={{ borderRadius: '6px',  backgroundColor: "#f7f7f7" }}>
                        <div className="text-900 font-medium text-xl mb-2">Performance</div>
                        <div className="text-600">Adapté aux entreprises en développement souhaitant avoir de nouveaux clients</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="align-items-center">
                          <span className="font-bold text-2xl text-900">1 an: 500€/mois</span>
                          <br/>
                          <span className="font-medium text-600">6 mois: 550€/mois</span>
                          <br/>
                          <span className="font-medium text-600">3 mois: 600€/mois</span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <ul className="list-none p-0 m-0 flex-grow-1">
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span>2 réseaux sociaux</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span>16 publications</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span style={{textAlign: "left"}}>Modération et animation (répondre au messages et stories)</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span style={{textAlign: "left"}}>Utilisation de la charte graphique personnalisée</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span style={{textAlign: "left"}}>Veille et recherche de contenu</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span>Bilan mensuel</span>
                          </li>
                        </ul>
                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                        <a href="#contact" class="spanLink"><Button label="Contactez-nous" className="p-3 w-full p-button-outlined" /></a>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 lg:col-4">
                    <div className="p-3 h-full">
                      <div className="shadow-3 p-3 flex flex-column" style={{ borderRadius: '6px',  backgroundColor: "#f7f7f7" }}>
                        <div className="text-900 font-medium text-xl mb-2">Boost</div>
                        <div className="text-600">Idéal pour les entreprises qui débutent afin de se construire une clientèle</div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <div className="flex align-items-center">
                          <span className="font-bold text-2xl text-900">3 mois: 700€/mois</span>
                        </div>
                        <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                        <ul className="list-none p-0 m-0 flex-grow-1">
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span>1 réseau social</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span>30 publications</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span style={{textAlign: "left"}}>Modération et animation (répondre au messages et stories)</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span style={{textAlign: "left"}}>Utilisation de la charte graphique personnalisée</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span style={{textAlign: "left"}}>Veille et recherche de contenu</span>
                          </li>
                          <li className="flex align-items-center mb-3">
                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                            <span>Bilan mensuel</span>
                          </li>
                        </ul>
                        <br/><br/>
                        <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                        <a href="#contact" class="spanLink"><Button label="Contactez-nous" className="p-3 w-full" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="skew-cc" />
        </div>
        <br /><br /><br /><br /><br />


        {/* Contact */}
        <span id="contact"></span>
        <br /><br />
        <div className='contactContainer'>
          <div class='subTitleWrapper'>
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} initiallyVisible={false} duration={1}>
              <h1 id="h1contact">Contact</h1>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} initiallyVisible={false} duration={1} delay={100}>
              <span id='subTitleContact'>Au plus proche de vous</span>
            </AnimationOnScroll>
            <br />
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} initiallyVisible={false} duration={1} delay={100}>
              <img src={contact} id="responsiveTitleLogos" style={{ width: '5%', opacity: '1' }} />
            </AnimationOnScroll>
          </div>
          <br /><br />
          <div class="signupSection">
            <div class="info">
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={1} delay={400}>
                <h2>Informations</h2>
                <br />
                <div>
                  <div class="flex flex-column contactInfoCol align-items-center justify-content-center">
                    {/* rel attribute sets the relationship between homepage and the linked URL */}
                    {/* noopener noreferrer is to prevent a type of phishing known as tabnabbing */}
                    <a href="mailto: alexia@partner-indesign.fr" target="_blank" rel="noopener noreferrer" id="disableTextDecoration">
                      <div class="flex contactInfoCard border-round m-2">
                        <div class="contactInfoContent p-0">
                          <div class="inline text-white text-center">
                            <Avatar icon="pi pi-send" className="mr-2" size="small" style={{ backgroundColor: '#FFB572', color: '#ffffff' }} shape="circle" />
                          </div>
                          <div class="inline text-white text-center">
                            alexia@partner-indesign.fr
                          </div>
                        </div>
                      </div>
                    </a>
                    <a href="mailto: steeven@partner-indesign.fr" target="_blank" rel="noopener noreferrer" id="disableTextDecoration">
                      <div class="flex contactInfoCard border-round m-2">
                        <div class="contactInfoContent p-0">
                          <div class="inline text-white text-center">
                            <Avatar icon="pi pi-send" className="mr-2" size="small" style={{ backgroundColor: '#FFB572', color: '#ffffff' }} shape="circle" />
                          </div>
                          <div class="inline text-white text-center">
                            steven@partner-indesign.fr
                          </div>
                        </div>
                      </div>
                    </a>
                    {/* <a href="mailto: info@partner-indesign.fr" target="_blank" rel="noopener noreferrer" id="disableTextDecoration">
                      <div class="flex contactInfoCard border-round m-2">
                        <div class="contactInfoContent p-0">
                          <div class="inline text-white text-center">
                            <Avatar icon="pi pi-send" className="mr-2" size="small" style={{ backgroundColor: '#FFB572', color: '#ffffff' }} shape="circle" />
                          </div>
                          <div class="inline text-white text-center">
                            info@partner-indesign.fr
                          </div>
                        </div>
                      </div>
                    </a> */}
                    <a href="https://www.instagram.com/partner.indesign/" target="_blank" rel="noopener noreferrer" id="disableTextDecoration">
                      <div class="flex contactInfoCard border-round m-2">
                        <div class="contactInfoContent p-0">
                          <div class="inline text-white text-center">
                            <Avatar icon="pi pi-instagram" className="mr-2" size="small" style={{ backgroundColor: '#FFB572', color: '#ffffff' }} shape="circle" />
                          </div>
                          <div class="inline text-white text-center">
                            Partner in design
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div class="contact">
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={1} delay={600}>
                <form ref={form} onSubmit={handleSubmit(onSubmit)} class="contactForm p-fluid">
                  <h2>Contactez-nous !</h2>
                  <div className="field">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-user" />
                      <InputText value={registeredName} onChange={(e) => setRegisteredName(e.target.value)} id="formWhiteInputColor" />
                      <label htmlFor="name">Nom</label>
                    </span>
                  </div>
                  <div className="field">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-user" />
                      <InputText value={registeredFirstName} onChange={(e) => setRegisteredFirstName(e.target.value)} id="formWhiteInputColor" />
                      <label htmlFor="firstName">Prénom</label>
                    </span>
                  </div>
                  <div className="field">
                    <span className="p-float-label p-input-icon-right">
                      <i className="pi pi-envelope" />
                      <InputText value={registeredEmail} onChange={(e) => setRegisteredEmail(e.target.value)} id="formWhiteInputColor" />
                      <label htmlFor="email">Email</label>
                    </span>
                  </div>
                  <div className="contactEditor">
                    <textarea value={registeredEmailText} onChange={(e) => setEmailText(e.target.value)} class="contentEmailContent" />
                  </div>
                  <Button type="submit" label="Envoyer" className="p-button-raised mt-2" id="customBtnWidth" />
                </form>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
        <br /><br /><br /><br />

        {/* Footer */}
        <Footer />

      </div>

    </div>
  );
}