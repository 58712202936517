import React from 'react';
import LoaderGif from '../media/loader.gif';
import '../style/LoaderStyle.css';

export default function Loader() {
    return(
        <div className='loaderContainer'>
            <img src={LoaderGif} class="loaderResize"/>
        </div>
    )
}