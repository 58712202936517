/* ============ */
/* Local imports */
/* ============ */

import logo from '../../media/logo.png';
import presentation from '../../media/navbar/presentation.png';
import portfolio from '../../media/navbar/portfolio.png';
import prestations from '../../media/navbar/prestations.png';
import contact from '../../media/navbar/contact.png';

/* CSS */

import '../../style/Navigation.css'

/* ================ */
/* External imports */
/* ================ */

/* React & Router imports */

import React, { useState, useRef, useEffect } from 'react';
import {Link} from "react-router-dom";

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

/* PrimeReact */
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

/* Firebase & FireStore */
import {auth} from "../../../firebase.config.js";
import {app} from "../../../firebase.config.js";
import {onAuthStateChanged, signOut, signInWithEmailAndPassword} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";


export default function Navigation() {

  useEffect(() => console.log('Navbar & login mounted'), []);

  /* Navbar on scroll */  
  const [stickyClass, setStickyClass] = useState('relative');
  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);
  let windowWidth = window.innerWidth;
  // console.log(windowWidth);
  const stickNavbar = () => {
    if (window !== undefined && windowWidth > 500) {
      let windowHeight = window.scrollY;
      windowHeight < 870 ? setStickyClass('') : setStickyClass('relative'); // windowHeight > x, x = 
      windowHeight > 870 ? setStickyClass('fixed top-0 left-0 z-50') : setStickyClass('relative'); // windowHeight > x, x = 
    }else if(window !== undefined){
      let windowHeight = window.scrollY;
      windowHeight < 220 ? setStickyClass('') : setStickyClass('relative'); // windowHeight > x, x = 
      windowHeight > 220 ? setStickyClass('fixed top-0 left-0 z-50') : setStickyClass('relative'); // windowHeight > x, x = 
    }
  };

  /* Login onClick: Modal */
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState('center');
  const dialogFuncMap = {
    'displayResponsive': setDisplayResponsive
  }
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  }
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }  
  /* Login/out boolean */
  const [loginBool, setLoginBool] = useState(false)
  /* Login checker & Firebase login */
  const [loginEmail, setLoginEmail] = useState();
  const [loginPwd, setLoginPwd] = useState();
  const [checked, setChecked] = useState(false);
  const toast = useRef(null);
  let [user, setUser] = useState({});
  let [getUid, setUid] = useState({});
  let [getEmail, setEmail] = useState({});
  let [getDashboardPath, setDashboardPath] = useState();
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
    setUid(currentUser.uid);
    setEmail(currentUser.email);
    setDashboardPath("http://localhost:8080/account/dashboard:" + getUid);
  })
  const login = async () => {
  try{
    const user = await signInWithEmailAndPassword(
      auth,
      loginEmail,
      loginPwd
      );
      asyncAdd();
      setLoginBool(true);
      toast.current.show({severity:'success', summary: 'Succès', detail:loginEmail + ' connecté', life: 3000});
      onHide('displayResponsive');
    } catch(error){
      console.log(error.message);
      setLoginBool(false);
      toast.current.show({severity:'error', summary: 'Erreur', detail: 'Veuillez vérifier vos identifiants de connexion ou créer un compte', life: 3000});
    }
  };
  async function asyncAdd() {
    try {
      const db = getFirestore(app);
      const docRef = await addDoc(collection(db, getEmail), {
        first: "Ada",
        last: "Lovelace",
        born: 1815
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }      
  }
  /* Checkers: Policy requirements */
  function handleSubmit(){
    console.log("Submit chercker")
    let verifications = "";
    if (loginEmail && loginEmail != "") {
      console.log("Email policy requirements is correct");
    } else {
      console.log("Email policy requirements is not correct")
      verifications += "Email";
    }
    if (loginPwd && loginPwd != "") {
      console.log("Email policy requirements is correct");
    } else {
      console.log("Email policy requirements is not correct")
      verifications += "Mot de passe";
    }
    if(verifications && verifications != ""){
      toast.current.show({severity:'error', summary: 'Erreur', detail: 'Veuillez vérifier le(s) champ(s) suivant(s): ' + verifications, life: 3000});
    }else{
      login()
    }
  }

  /* Firebase logout */
  const logout = async () => {
    await signOut(auth)
    toast.current.show({severity:'info', summary: 'Info', detail:loginEmail + ' déconnecté', life: 3000});
    setLoginBool(false);
    window.location.reload(false);
  };

  /* Open dashboard in new window */
  function newWindowDashboard(){
    window.open(getDashboardPath); // Open new window with account dashboard
  };

  return (

    <div>
      
      {/* Navbar */}

      <Navbar className={`${stickyClass}`} collapseOnSelect expand="lg" bg="light" variant="light">
        
        <Toast ref={toast} />

        <Container>
        <Link to="/home" class="navbarHomeLink"><Navbar.Brand class="pacificoFont" id="textShadows"><img src={logo} className="responsiveNavLogo" /></Navbar.Brand></Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/* <Link to="/home" style={{textDecoration:'none'}}><Nav.Link href="#component/1">Accueil</Nav.Link></Link> */}
              <img src={presentation} class="navbarNavLogos" /><Nav.Link href="#presentation" id="responsiveNavText">Présentation</Nav.Link>
              <img src={portfolio} class="navbarNavLogos" /><Nav.Link href="#portfolio" id="responsiveNavText">Portfolio</Nav.Link>
              <img src={prestations} class="navbarNavLogos" /><Nav.Link href="#prestations" id="responsiveNavText">Préstations</Nav.Link>
              <img src={contact} class="navbarNavLogos" /><Nav.Link href="#contact" id="responsiveNavText">Contact</Nav.Link>

              {loginBool ? 
                (
                  <Nav.Link onClick={newWindowDashboard} style={{ textDecoration: "none" }}>Tableau de bord</Nav.Link>
                ) : (
                  // <Nav.Link disabled onClick={newWindowDashboard} style={{ textDecoration: "none" }}>Tableau de bord</Nav.Link>
                  <span></span>
                )
              }
              
            </Nav>
            <Nav>
              <Nav.Link>

                {/* {user?.email} */}

                {loginBool ? 
                  (
                  
                  <Button label="Déconnexion" onClick={logout} icon="pi pi-sign-out" id="logoutBtn" className="p-button-raised p-button" />
                  ) : (
                  <Button label="Connexion" onClick={() => onClick('displayResponsive')} icon="pi pi-user" className="p-button-raised p-button" />               
                  )
                }

              </Nav.Link>
              <Dialog visible={displayResponsive} onHide={() => onHide('displayResponsive')} maximizable breakpoints={{'960px': '75vw'}} style={{width: '20vw'}} >
                <div className="flex align-items-center justify-content-center">
                  <div>
                    <div className="text-center mb-5">
                      <div className="text-900 text-3xl font-medium mb-3">Bienvenue !</div>
                      <span className="text-600 font-medium line-height-3">Pas de compte?</span>
                      <Link to="/account/register" style={{textDecoration:'none'}}><a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Créez-en un maintenant !</a></Link>
                    </div>

                    <div className="loginContainer">
                      <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                      <InputText id="email" type="text" className="w-full mb-3" value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} />

                      <label htmlFor="password" className="block text-900 font-medium mb-2">Mot de passe</label>
                      <InputText id="password" type="password" className="w-full mb-3" value={loginPwd} onChange={(e) => setLoginPwd(e.target.value)}/>

                      {/* <div className="flex align-items-center justify-content-between mb-6">
                        <Link to="/account/password" style={{textDecoration:'none'}}><a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Mot de passe oublié?</a></Link>
                      </div> */}

                      <Button label="Se connecter" icon="pi pi-user" className="p-button-raised w-full" onClick={handleSubmit} />
                    </div>
                  </div>
                </div>
              </Dialog>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}