/* ============ */
/* Local imports */
/* ============ */

/* CSS */

import '../../style/ScrollIndicator.css'

/* ================ */
/* External imports */
/* ================ */

/* React & Router imports */

import React, { useEffect, useState } from 'react';

/* React-use */
import { useWindowScroll } from 'react-use';

const ScrollIndicator = () => {
    const {x,y} = useWindowScroll()
    const[scrolled, setScrolled] = useState(0)

    useEffect(() =>{
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        setScrolled( ( y/height ) * 100 )
    },[y])

    return(
        <div class="scrollContainer">
            <div class="scrollIndicator" style={{width: `${scrolled}%`}}>

            </div>
        </div>
    )
}

export default ScrollIndicator