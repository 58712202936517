/* ============ */
/* Local imports */
/* ============ */

/* CSS */
import '../../style/FeedbackNav.css'

/* ================ */
/* External imports */
/* ================ */

/* React & Router imports */
import React, {useEffect, useState, useRef} from 'react';


/* PrimeReact imports */
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Slider } from 'primereact/slider';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';

/* EmailJS */
import emailjs from '@emailjs/browser';

export default function FeedbackNav() {

  /* Notifications */
  const toast = useRef(null);

  /* Modal */  
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [position, setPosition] = useState('center');

  const dialogFuncMap = {
    'displayResponsive': setDisplayResponsive
  }
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  }
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  }  

  /* Formulaire: Devis & EmailJS */
  const [formData, setFormData] = useState({});
  const defaultValues = {
    prestation: 'Pestation is empty',
    price: 'Price is empty',
    company: 'Company is empty',
    email: 'Email is empty',
    name: 'Name is empty',
    firstName: 'First name is empty'
  }
  const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
  const onSubmit = (data) => {
    setFormData(data);
    submitChecker();
    reset();
  };
  /* Checkers: Policy requirements */
  function submitChecker(){
    console.log("Submit checker")
    let verifications = "";
    if (selectedCountry && selectedCountry != "") {
      console.log("Selected country policy requirements is correct");
    } else {
      console.log("Selected country policy requirements is not correct")
      verifications += "Pays";
    }
    if (prestationToSend && prestationToSend != "") {
      console.log("Prestation type policy requirements is correct");
    } else {
      console.log("Prestation type policy requirements is not correct")
      verifications += "Préstation";
    }
    if (selectedPrice && selectedPrice != "") {
      console.log("Price policy requirements is correct");
    } else {
      console.log("Price policy requirements is not correct")
      verifications += "Prix";
    }
    if (selectedCompany && selectedCompany != "") {
      console.log("Company policy requirements is correct");
    } else {
      console.log("Company policy requirements is not correct")
      verifications += "Type d'entreprise";
    }
    if (registeredEmail && registeredEmail != "") {
      console.log("Email policy requirements is correct");
    } else {
      console.log("Email policy requirements is not correct")
      verifications += "Email";
    }
    if (registeredName && registeredName != "") {
      console.log("Name policy requirements is correct");
    } else {
      console.log("Name policy requirements is not correct")
      verifications += "Nom";
    }
    if (registeredFirstName && registeredFirstName != "") {
      console.log("First name policy requirements is correct");
    } else {
      console.log("First name policy requirements is not correct")
      verifications += "Prénom";
    }
    if(verifications && verifications != ""){
      toast.current.show({severity:'error', summary: 'Erreur', detail: 'Veuillez vérifier le(s) champ(s) suivant(s): ' + verifications, life: 3000});
    }else{
      sendEmail();
    }
  }
  const getFormErrorMessage = (name) => {
      return errors[name] && <small className="p-error">{errors[name].message}</small>
  };
  const [selectedCountry, setSelectedCountry] = useState('');
  const [prestationToSend, setPresetationToSend] = useState('');
  const countries = [
    { name: 'Conception de logo' },
    { name: 'Brochure' },
    { name: 'Carte de visite' },
    { name: 'Flyer' },
    { name: 'Emballage de produit' },
    { name: 'Affiche' },
    { name: "Carton d'invitation" },
    { name: 'Identité visuelle' },
    { name: 'E-mailing' },
    { name: 'Landing page' },
    { name: 'Bannière publicitaire' },
    { name: 'Visuel de réseaux sociaux' },
    { name: 'Visuel personnalisé de réseaux sociaux' },
    { name: 'Conception de site web vitrine simple' },
    { name: 'Conception de site web vitrine personnalisé' },
    { name: 'Conception de site web sur mesure' },
    { name: 'Forfait de lancement' }
  ];
  const onCountryChange = (e) => {
      setSelectedCountry(e.value);
  }
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      setPresetationToSend(option.name);
        return (
          <div className="country-item country-item-value">
            <div>{option.name}</div>
          </div>
        );
    }
    return (
      <span>
        {props.placeholder}
      </span>
    );
  }
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  }
  const [selectedPrice, setSelectedPrice] = useState(2500);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const onCompanyChange = (e) => {
    let companyType = [...selectedCompany];
    if (e.checked)
      companyType.push(e.value);
    else
      companyType.splice(companyType.indexOf(e.value), 1);
    setSelectedCompany(companyType);
  }
  const [registeredEmail, setRegisteredEmail] = useState('');
  const [registeredName, setRegisteredName] = useState('');
  const [registeredFirstName, setRegisteredFirstName] = useState('');

  const form = useRef();
  const sendEmail = () => {
    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    // "service_sxry44p","template_pkblubl","jzKvLruPClzmTUsWW"
    var templateParams = {
      prestation: prestationToSend,
      price: selectedPrice,
      company: selectedCompany,
      email: registeredEmail,
      name: registeredName,
      firstName: registeredFirstName
    };
    emailjs.send('service_sxry44p','template_pkblubl', templateParams, 'jzKvLruPClzmTUsWW')
    .then(function(response) {
      console.log('SUCCESS!', response.status, response.text);
      toast.current.show({severity:'success', summary: 'Devis créé avec succès !', detail:'Nous vous contacterons rapidement', life: 3000});
    }, function(error) {
      console.log('FAILED...', error);
      toast.current.show({severity:'error', summary: 'Erreur', detail: {error}, life: 3000});
    });
  };

  
  return (

    <div class="feedbackContainer">
      <Toast ref={toast} />

      {/* <a href="mailto:info@partner-indesign.fr?subject=Devis&body=Body-goes-here" class="spanLink"></a> */}
      <Button label="Créez votre devis !" onClick={() => onClick('displayResponsive')} icon="pi pi-send" className="p-button-raised p-button p-button-lg" />

      <Dialog visible={displayResponsive} onHide={() => onHide('displayResponsive')} maximizable breakpoints={{'960px': '75vw'}} style={{width: '40vw'}} >
          <div class="devisContainer">  
            <div className="flex align-items-center justify-content-center">
              <div className="surface-card w-full lg:w-6">
                <div className="text-center">
                  {/* <img src={GAPLogo} alt="gap" height={50} className="mb-3" /> */}
                  <div className="text-900 text-3xl font-medium">Créer un devis</div>
                </div>
                <div>
                  <div className="flex justify-content-center">
                    <form ref={form} onSubmit={handleSubmit(onSubmit)} className="p-fluid">

                      <div>
                        <label htmlFor="company" className="customLabel" id="formSpace">Quel est votre projet?</label>
                        <span className="p-float-label">
                          <Dropdown onChange={onCountryChange}
                          value={selectedCountry} options={countries}
                          optionLabel="name"
                          filter showClear filterBy="name"
                          placeholder="Quel est votre projet?"
                          valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} />
                          {/* <label htmlFor="name">Quel est votre projet?*</label> */}
                        </span>
                      </div>

                      <div>
                        <span>
                          <br/>
                          <label htmlFor="price" id="formLabelColor">Votre budget: jusqu'à {selectedPrice}€</label>
                          <br/>
                          <InputText value={selectedPrice} onChange={(e) => setSelectedPrice(e.target.value)} />
                          <Slider value={selectedPrice} onChange={(e) => setSelectedPrice(e.value)} step={1} min={86} max={6000} />
                        </span>
                      </div>

                      <div >
                        <span>
                          <label htmlFor="company" className="customLabel" id="formSpace">Quelle société êtes-vous?</label>
                          <div className="field-checkbox">
                            <Checkbox inputId="companyType1" name="city" value="Particulier" onChange={onCompanyChange} checked={selectedCompany.indexOf('Particulier') !== -1} />
                            <label htmlFor="companyType1">Particulier</label>
                          </div>
                          <div className="field-checkbox">
                            <Checkbox inputId="companyType2" name="city" value="Moins de 10 employés" onChange={onCompanyChange} checked={selectedCompany.indexOf('Moins de 10 employés') !== -1} />
                            <label htmlFor="companyType2">Moins de 10 employés</label>
                          </div>
                          <div className="field-checkbox">
                            <Checkbox inputId="companyType3" name="city" value="Plus de 10 employés" onChange={onCompanyChange} checked={selectedCompany.indexOf('Plus de 10 employés') !== -1} />
                            <label htmlFor="companyType3">Plus de 10 employés</label>
                          </div>
                        </span>
                      </div>

                      <div className="field" id="formSpace">
                        <span className="p-float-label p-input-icon-right">
                          <i className="pi pi-user" />
                          <InputText value={registeredName} onChange={(e) => setRegisteredName(e.target.value)}/>
                          <label htmlFor="name" id="formInputColor">Nom*</label>
                        </span>
                      </div>

                      <div className="field" id="formSpace">
                        <span className="p-float-label p-input-icon-right">
                          <i className="pi pi-user" />
                          <InputText value={registeredFirstName} onChange={(e) => setRegisteredFirstName(e.target.value)}/>
                          <label htmlFor="name" id="formInputColor">Prénom*</label>
                        </span>
                      </div>

                      <div className="field">
                        <span className="p-float-label p-input-icon-right" id="formSpace">
                          <i className="pi pi-envelope" />
                          <InputText value={registeredEmail} onChange={(e) => setRegisteredEmail(e.target.value)}/>
                          <label htmlFor="email" id="formInputColor">Email*</label>
                        </span>
                      </div>
                      <Button type="submit" label="Envoyer" className="p-button-raised mt-2" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </Dialog>
    </div>
    

  );
}