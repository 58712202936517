import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyA3KgLxqFbs79R3QJBrh5MXQXNCIPgV0aw",
  // process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "partner-indesign.firebaseapp.com",
  // process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: "partner-indesign",
  // process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: "partner-indesign.appspot.com",
  // process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: "918283846261",
  // process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: "1:918283846261:web:8a4eee5a7391fc2fd06e3c",
  // process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-M2HY3EJWSK",
  // process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);