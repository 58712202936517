/* ============ */
/* Local imports */
/* ============ */

/* CJ, R2, ... */
import PortfolioCJ from './PortfolioCJ';
import PortfolioDB from './PortfolioDB';
import PortfolioR2 from './PortfolioR2';
import PortfolioBarberousse from './PortfolioBarberousse';

/* Media */
import portfolio from '../../media/navbar/portfolio.png';

/* CSS */
import '../../style/Portfolio.scss'

/* ================ */
/* External imports */
/* ================ */

/* React & Router imports */
import React, { useState, useEffect } from 'react';

/* PrimeReact */
import { Sidebar } from 'primereact/sidebar';

/* Animations */
// https://github.com/MetinArslanturk/react-animation-on-scroll
// https://www.metinarslanturk.com/react-animation-on-scroll/
// https://animate.style/
import { AnimationOnScroll } from 'react-animation-on-scroll';


export default function Portfolio() {

  useEffect(() => console.log('Homepage portfolio mounted'), []);

  const [isVisibleCJ, setVisibleCJ] = useState(false);
  const [isVisibleDB, setVisibleDB] = useState(false);
  const [isVisibleR2, setVisibleR2] = useState(false);
  const [isVisibleBarberousse, setVisibleBarberousse] = useState(false);
  const html = document.getElementsByTagName('html')[0]
  if((isVisibleCJ || isVisibleDB || isVisibleR2) == true){
    html.classList.add('lock-scroll')
  }else{
    html.classList.remove('lock-scroll')
  }

  return (

    <div>
      <div class="portfolioContainer">
        <div class='subTitleWrapper'>
          <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} initiallyVisible={false} duration={1}>
            <h1 id="h1portfolio">Portfolio</h1>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} initiallyVisible={false} duration={1} delay={100}>
            <span id='subTitlePortfolio'>Dossier numérique des réalisations</span>
          </AnimationOnScroll>
          <br/>
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={1} delay={350}>
            <img src={portfolio} id="responsiveTitleLogos" style={{ width: '5%', opacity: '1' }} />
          </AnimationOnScroll>
        </div>
        <br/><br/>

        <div class="portfolioSubContainer">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={2.25} delay={350}>
            <div class="portfolioBackground">
              <div id="portfolioImgCJ" onClick={() => setVisibleCJ(true)}>
                <div id="portfolioTxtOverImg">
                  2022
                  <br/>
                  C&J Beauty
                  <br/>
                  Institut de beauté et soins esthétiques
                </div>
              </div>
            </div>
          </AnimationOnScroll>
          <Sidebar visible={isVisibleCJ} position="bottom" onHide={() => setVisibleCJ(false)} modal={false} dismissable>
            <PortfolioCJ/>
          </Sidebar>

          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={1.25} delay={350}>
            <div class="portfolioBackground">
              <div id="portfolioImgR2" onClick={() => setVisibleDB(true)}>
                <div id="portfolioTxtOverImg">
                  2022
                  <br/>
                  D&B Coaching
                  <br/>
                  Coaching sportif
                </div>
              </div>
            </div>
          </AnimationOnScroll>
          <Sidebar visible={isVisibleDB} position="bottom" onHide={() => setVisibleDB(false)} modal={false} dismissable>
            <PortfolioDB/>
          </Sidebar>

          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={1.25} delay={350}>
            <div class="portfolioBackground">
              <div id="portfolioImgDB" onClick={() => setVisibleR2(true)}>
                <div id="portfolioTxtOverImg">
                  2022
                  <br/>
                  R2
                  <br/>
                  Conseils en gestion d'entreprise
                </div>
              </div>
            </div>
          </AnimationOnScroll>
          <Sidebar visible={isVisibleR2} position="bottom" onHide={() => setVisibleR2(false)} modal={false} dismissable>
            <PortfolioR2/>
          </Sidebar>

          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true} initiallyVisible={false} duration={1.25} delay={350}>
            <div class="portfolioBackground">
              <div id="portfolioImgBarberousse" onClick={() => setVisibleBarberousse(true)}>
                <div id="portfolioTxtOverImg">
                  2022
                  <br/>
                  Barberousse
                  <br/>
                  Franchise de bars
                </div>
              </div>
            </div>
          </AnimationOnScroll>
          <Sidebar visible={isVisibleBarberousse} position="bottom" onHide={() => setVisibleBarberousse(false)} modal={false} dismissable>
            <PortfolioBarberousse/>
          </Sidebar>
        </div>
      </div>
    </div>

  );
}