export const portfolioDataDB = [
    {
        path: require("../../../media/portfolio/D&B/Plan de travail 2.png"),
        name: "Recto",
        description: "Carte de visite"
    },
    {
        path: require("../../../media/portfolio/D&B/Plan de travail 2_1.png"),
        name: "Verso",
        description: "Carte de visite"
    },
    {
        path: require("../../../media/portfolio/D&B/Plan de travail 2_2.png"),
        name: "Plan de travail",
        description: "Flyer"
    },
];