/* ============ */
/* Local imports */
/* ============ */

/* CSS */

import '../style/Global.css'
import '../style/Password.css'

/* Shared */

import Footer from './shared/Footer';

/* ================ */
/* External imports */
/* ================ */

/* React imports*/

import React, { useState, useEffect, useRef } from 'react';

/* Prime imports */

import "primeflex/primeflex.css";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
// import { StyleClass } from 'primereact/styleclass';


export default function Password() {

  useEffect(() => console.log('Password mounted'), []);

  const [resetPwd, setResetPwd] = useState();
  const toast = useRef(null);

  function handleSubmit() {

    console.log("Handle submit")

    if (resetPwd && resetPwd != "") {
      console.log("Email is correct");
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'Please reset your password with received email at ' + resetPwd, life: 3000 });
    } else {
      console.log("Email is not correct")
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Email is not correct', life: 3000 });
    }
  }

  return (

    <div>

      {/* Rest password */}

      <Toast ref={toast} />

      <div class="passwordContainer">
        <div className="flex align-items-center justify-content-center">
          <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <div className="text-center mb-5">
              {/* <img src={GAPLogo} alt="gap" height={50} className="mb-3" /> */}
              <div className="text-900 text-3xl font-medium mb-3">Réinitialiser son mot de passe</div>
              <span className="text-600 font-medium line-height-3">Regardez vos e-mails après avoir compléter le formulaire</span>
            </div>

            <div>
              <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
              <InputText id="email" type="text" className="w-full mb-3" value={resetPwd} onChange={(e) => setResetPwd(e.target.value)} />

              {/* <label htmlFor="text" className="block text-900 font-medium mb-2">Company</label>
              <InputText id="text" type="text" className="w-full mb-3" /> */}

              {/* <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
              <InputText id="password" type="password" className="w-full mb-3" />

              <label htmlFor="password" className="block text-900 font-medium mb-2">Confirm password</label>
              <InputText id="password" type="password" className="w-full mb-3" /> */}

              <Button label="Envoyer" icon="pi pi-key" className="p-button-raised w-full" id="passwordButton" onClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>

      <Footer />

    </div>

  );
}