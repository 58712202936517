import React from 'react';
import { Carousel } from 'primereact/carousel';
import {portfolioDataDB} from "./data/portfolioDataD&B.js";

export default function PortfolioDB() {

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
      ];
    
      const portfolioSrc = (image) => {
        return (
          <div>
            <div>
              <div>
                <img style={{ width: '100%', opacity: '1' }} src={`${image.path}`} id="imgPortfolioFeatures" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={image.name} />
              </div>
            </div>
          </div>
        );
      }

    return(
        <div class="portfolioProjectRow">
        <div class="portfolioProjectCard portfolioProjectSplitCol portfolioProjectSplitContent">
        <div class="portfolioProjectContainer">
            <div class="portfolioProjectRow"> 
            <div class="portfolioProjectSplitCol">
                <div class="portfolioProjectCardSect">
                <div class="portfolioProjectItem">
                    <div class="portfolioProjectHead">
                    <div class="portfolioProjectTitle">
                        Réalisations 
                    </div>
                    </div>
                    <div class="portfolioProjectContent">
                    <Carousel value={portfolioDataDB} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions}
                        itemTemplate={portfolioSrc} />
                    {/* autoplayInterval={6000} circular */}
                    </div>
                </div>
                </div>
            </div>
            <div class="portfolioProjectSplitCol" id="dividerWrap"> 
                <div class="contentDivider">
                <div class="dividedText"><strong>
                    &
                </strong></div>
                </div>
            </div> 
            <div class="portfolioProjectSplitCol">
                <div class="portfolioProjectCardSect">
                <div class="portfolioProjectItem">
                    <div class="portfolioProjectHead">
                    <div class="portfolioProjectTitle">
                        D&B Coaching 
                    </div>
                    </div>
                    <div class="portfolioProjectContent">
                    <p>
                        Coaching sportif
                    </p>
                    <p>
                        Élaboration de la charte graphique en adéquation avec les valeurs de la marque.
                        Création de flyers promotionnels et cartes de visite dans un univers dynamique.
                        Conception d'un site web sur mesure en cours.
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        </div>
    );
}