import React from 'react';
import { Carousel } from 'primereact/carousel';
import {portfolioDataR2} from "./data/portfolioDataR2.js";

export default function PortfolioR2() {

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
      ];
    
      const portfolioSrc = (image) => {
        return (
          <div>
            <div>
              <div>
                <img style={{ width: '100%', opacity: '1' }} src={`${image.path}`} id="imgPortfolioFeatures" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={image.name} />
              </div>
            </div>
          </div>
        );
      }

    return(
        <div class="portfolioProjectRow">
        <div class="portfolioProjectCard portfolioProjectSplitCol portfolioProjectSplitContent">
        <div class="portfolioProjectContainer">
            <div class="portfolioProjectRow"> 
            <div class="portfolioProjectSplitCol">
                <div class="portfolioProjectCardSect">
                <div class="portfolioProjectItem">
                    <div class="portfolioProjectHead">
                    <div class="portfolioProjectTitle">
                        Réalisations 
                    </div>
                    </div>
                    <div class="portfolioProjectContent">
                    <Carousel value={portfolioDataR2} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions}
                        itemTemplate={portfolioSrc} />
                    {/* autoplayInterval={6000} circular */}
                    </div>
                </div>
                </div>
            </div>
            <div class="portfolioProjectSplitCol" id="dividerWrap"> 
                <div class="contentDivider">
                <div class="dividedText"><strong>
                    &
                </strong></div>
                </div>
            </div> 
            <div class="portfolioProjectSplitCol">
                <div class="portfolioProjectCardSect">
                <div class="portfolioProjectItem">
                    <div class="portfolioProjectHead">
                    <div class="portfolioProjectTitle">
                        R2
                    </div>
                    </div>
                    <div class="portfolioProjectContent">
                    <p>
                        Conseils en gestion d'entreprise
                    </p>
                    <p>
                        Conception d'identité visuelle en accord avec les valeurs fondamentales de notre client.
                        Création de cartes de visite personnalisées. Réalisation d'un site vitrine sur mesure en cours.
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        </div>
    );
}