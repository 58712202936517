/* ============ */
/* Local imports */
/* ============ */

/* CSS */

import '../../style/Footer.css'

/* ================ */
/* External imports */
/* ================ */

/* React & Router imports */

import React from 'react';
import {
  Link
} from "react-router-dom";

export default function Footer() {

  return (

  <div>

    {/* Footer */}

    <footer class="footer">
      <h1 id="customFooterText">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Partner InDesign
      </h1>
      <div class="footer__addr">
        <h5>Vous avez un projet en tête?</h5>
        <h5>Créez un devis en ligne ou contactez-nous !</h5>
      </div>
      
      <ul class="footer__nav">
        <li class="nav__item">
          <h2 class="nav__title">Accueil</h2>

          <ul class="nav__ul">
            <li>
              <Link to="/home">Présentation</Link>
            </li>
            <li>
              <Link to="/home">Portfolio</Link>
            </li> 
            <li>
              <Link to="/home">Préstations</Link>
            </li>
            <li>
              <Link to="/home">Contact</Link>
            </li>
          </ul>
        </li>
        
        <li class="nav__item nav__item--extra">
          <h2 class="nav__title">Compte</h2>
          
          <ul class="nav__ul">
            <li>
              <Link to="/home">Se connecter</Link>
            </li>
            <li>
              <Link to="/account/register">S'enregistrer</Link>
            </li>
            <li>
              <Link to="/account/password">Réinitialiser son mot de passe</Link>
            </li>
          </ul>
        </li>
      </ul>
      
      <div class="legal">       
          <span class="legalRights">© 2022 All rights reserved</span>
      </div>
    </footer>
  </div>

  );
}