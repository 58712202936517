import React from 'react';
import { Carousel } from 'primereact/carousel';
import {portfolioDataCJ} from "./data/portfolioDataCJ.js";

export default function PortfolioCJ() {

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
      ];
    
      const portfolioSrc = (image) => {
        return (
          <div>
            <div>
              <div>
                <img style={{ width: '100%', opacity: '1' }} src={`${image.path}`} id="imgPortfolioFeatures" onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={image.name} />
              </div>
            </div>
          </div>
        );
      }

    return(
        <div class="portfolioProjectRow">
        <div class="portfolioProjectCard portfolioProjectSplitCol portfolioProjectSplitContent">
        <div class="portfolioProjectContainer">
            <div class="portfolioProjectRow"> 
            <div class="portfolioProjectSplitCol">
                <div class="portfolioProjectCardSect">
                <div class="portfolioProjectItem">
                    <div class="portfolioProjectHead">
                    <div class="portfolioProjectTitle">
                        Réalisations 
                    </div>
                    </div>
                    <div class="portfolioProjectContent">
                    <Carousel value={portfolioDataCJ} numVisible={3} numScroll={1} responsiveOptions={responsiveOptions}
                        itemTemplate={portfolioSrc} />
                    {/* autoplayInterval={6000} circular */}
                    </div>
                </div>
                </div>
            </div>
            <div class="portfolioProjectSplitCol" id="dividerWrap"> 
                <div class="contentDivider">
                <div class="dividedText"><strong>
                    &
                </strong></div>
                </div>
            </div> 
            <div class="portfolioProjectSplitCol">
                <div class="portfolioProjectCardSect">
                <div class="portfolioProjectItem">
                    <div class="portfolioProjectHead">
                    <div class="portfolioProjectTitle">
                        C&J Beauty 
                    </div>
                    </div>
                    <div class="portfolioProjectContent">
                    <p>
                        Institut de beauté et soins esthétiques
                    </p>
                    <p>
                        Création d'une identité visuelle fleurie, girly et chic ainsi que 3 affiches minimalistes.
                        Gestion des réseaux sociaux en accord avec l'identité visuelle.
                        Conception d'un <a href="https://www.cj-beauty.fr/" target="_blank" rel="noopener noreferrer" id="disableTextDecoration">site vitrine sur mesure.</a>
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        </div>
    );
}