export const portfolioDataBarberousse = [
    {
        path: require("../../../media/portfolio/barberousse/Plan de travail 2_5.png"),
        name: "Barberousse",
        description: "Website"
    },
    {
        path: require("../../../media/portfolio/barberousse/Plan de travail 2_6.png"),
        name: "Barberousse",
        description: "Website"
    },
    {
        path: require("../../../media/portfolio/barberousse/Plan de travail 2_7.png"),
        name: "Barberousse",
        description: "Website"
    },
];