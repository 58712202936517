export const portfolioDataR2 = [
    {
        path: require("../../../media/portfolio/R2/Plan de travail 2_3.png"),
        name: "Recto",
        description: "Flyer"
    },
    {
        path: require("../../../media/portfolio/R2/Plan de travail 2_4.png"),
        name: "Verso",
        description: "Flyer"
    },
];